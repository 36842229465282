<!--
 * @ Author: Clark
 * @ Create Time: 2023-02-05 20:20:57
 * @ Modified by: Your name
 * @ Modified time: 2023-02-14 11:50:50
 * @ Description: 营销管理>标签分组>用户分组>用户分组详情
 -->

<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
    </template>
    <div class="content">
      <ykc-detail-plus class="box-card" :labelWidth="100">
        <div slot="header" class="clearfix">
          <span>物业信息</span>
        </div>
        <div class="flexBox">
          <ykc-detail-item-plus label="物业编码">
            <span>{{ dealData(formData.propertyNo) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="物业名称">
            <span>{{ dealData(formData.propertyName) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="物业地址">
            <span>{{ dealData(formData.propertyAddress) }}</span>
          </ykc-detail-item-plus>
        </div>
        <div class="flexBox">
          <ykc-detail-item-plus label="资质详情">
            <span>{{ dealData(formData.propertyName) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="商业模式">
            <span>{{ dealData(formData.businessMode) }}</span>
          </ykc-detail-item-plus>

          <ykc-detail-item-plus label="联系人姓名">
            <span>{{ dealData(formData.contacts) }}</span>
          </ykc-detail-item-plus>
        </div>
        <div class="flexBox">
          <ykc-detail-item-plus label="联系人性别">
            <span>{{ dealData(formData.contactsSex) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="联系人手机">
            <span>{{ dealData(formData.contactsPhone) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="联系人职务">
            <span>{{ dealData(formData.contactsJob) }}</span>
          </ykc-detail-item-plus>
        </div>
        <div class="flexBox">
          <ykc-detail-item-plus label="联系人邮箱">
            <span>{{ dealData(formData.contactsEmail) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="联系人电话">
            <span>{{ dealData(formData.contactsTelephone) }}</span>
          </ykc-detail-item-plus>

          <ykc-detail-item-plus label="订单金额是否给物业" :labelWidth="200">
            <span>{{ formData.orderTo === 1 ? '是' : '否' }}</span>
          </ykc-detail-item-plus>
        </div>
        <div class="flexBox">
          <ykc-detail-item-plus label="收款单位名称">
            <span>{{ dealData(formData.company) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="收款银行">
            <span>{{ dealData(formData.bank) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="收款账号">
            <span>{{ dealData(formData.bankNo) }}</span>
          </ykc-detail-item-plus>
        </div>
      </ykc-detail-plus>
      <ykc-detail-plus class="box-card" :labelWidth="110">
        <div slot="header" class="clearfix">
          <span>站点信息</span>
        </div>
        <ykc-table
          ref="YkcTable"
          style="box-shadow: none"
          :data="tableData"
          :columns="tableColumns"></ykc-table>
      </ykc-detail-plus>
    </div>
  </scroll-layout>
</template>

<script>
  import { propertymentData } from '@/service/apis';
  import { dealData } from '../../../../utils/index';

  export default {
    name: 'PropertymentDetail',
    components: {},

    data() {
      return {
        id: '',
        formData: {},
        tableData: [],
        tableColumns: [
          { label: '站点ID', prop: 'stationId', minWidth: '100px' },
          { label: '站点名称', prop: 'stationName', minWidth: '150px' },
          { label: '入驻时间', prop: 'createTime', minWidth: '150px' },
          { label: '电站地址', prop: 'detailAddress', minWidth: '200px' },
          { label: '投资方', prop: 'investorName', minWidth: '200px' },
        ],
        pageButtons: [
          {
            id: '0',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
      };
    },
    computed: {},
    created() {
      const { id } = this.$route.query;
      this.id = id;
      this.queryDetail();
    },
    methods: {
      dealData,
      queryDetail() {
        // 编辑回显
        if (this.id) {
          propertymentData
            .propertymentDetail({ id: this.id })
            .then(res => {
              console.log(res);
              this.formData = res;
              this.tableData = res.stationOfPropertyDTOList;
              console.log(this.formData);
            })
            .catch(() => {});
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    .box-card {
      width: 100%;
      height: auto;
      margin: 10px 0px 0px 0px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      .flexBox {
        display: flex;
        margin-bottom: 20px;
      }
      .status {
        margin-left: 10px;
        color: #fff;
        height: 20px;
        border-radius: 10px;
        min-width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;
        line-height: 20px;
        &.stationStatus {
          &.status1000135 {
            background-color: #009f09;
          }
          &.status1000136 {
            background-color: #e81b1b;
          }
        }
        &.infoStatus {
          &.status1 {
            background-color: #009f09;
          }
          &.status2 {
            background-color: #ccc;
            color: #fff;
          }
          &.status0 {
            background-color: #e81b1b;
          }
        }
      }
      .title {
      }
    }
  }
</style>
